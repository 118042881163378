import React from 'react'
import { Link } from 'react-router-dom';
import '../About.css'

const About = () => {
    return (
        <>
            <div className="container-fluid">
                <div className='abt1'>
                    <h1 className='cont'>About FarmBridge</h1>
                </div>
                <div className='row abt1_1 d-flex'>
                    <div className='col-sm-12 col-md-12 col-xl-6'>
                        <p className='abt1_cont'>Indian Agriculture is becoming the most stressed profession due to its high reliability on atmospheric conditions and increased vulnerability in Climate Change. Irregular rainfall and extreme temperatures are posing unprecedented challenges to farmers in India and also causing a direct threat to the food security of our country as well as the world.
                        </p>
                        <p className='abt1_cont'>FARMBRIDGE SOCIAL SUPPORT FOUNDATION (FSSF) has identified various innovations and technologies to increase the adaptation for the farmers in the climate change phenomenon. These technologies also enable farmers to reduce their water consumption in irrigation, reduce the carbon emissions in agriculture & increase in soil health.
                        </p>
                    </div>
                    <div className='col-sm-12 col-md-12 col-xl-6'>
                        <p className='abt1_cont'>
                        FARMBRIDGE SOCIAL SUPPORT FOUNDATION (FSSF) is a Section-8 Non-Profit Company incorporated in 2015 with a vision of empowering the Highly Vulnerable Rural community with Climate Change Adaptation and Climate Risk Mitigation. FSSF is working in empowering the rural community with more than 10,000 farmers in more than 150 villages in 9 States in India.
                        </p>
                        <p className='abt1_cont'>
                        Our approach aims to provide a platform for farmers to significantly enhance their income by reducing input costs and improving their productivity. We aim to empower farmers by equipping them with the necessary tools and knowledge to optimize their farming processes, reduce costs, increase yields, and ultimately improve their income.
                        </p>
                    </div>
                </div>
            </div>
            <div className='abt1_btn'>
                <div className='abt1_btn1'>
                    <Link to="/" className='abt1_btn2'>BACK</Link>
                </div>
            </div>
        
        </>
    )
}

export default About;