import React from 'react';
import { Link } from 'react-router-dom';
import '../Footer.css'


const Footer = () => {
    return (
        <>
            <div className='sec6'>
                <div className='conatiner-fluid'>
                <div className='row'>
                    <div className='col-sm-12 col-md-6 col-lg-4 sec6_tit alg'>
                        <div className='sec6_tit1'>
                            <h5>FARMBRIDGE SOCIAL SUPPORT FOUNDATION</h5>
                            {/* <sp>Social Support Foundation</sp */}
                        </div>
                        <div className='sec6_p'>
                            <p> The key thrust areas identified by the government— Capacity building, Skill development, Environment & Natural Resource Management. The focal points of our interventions is Rural Livelihood and Climate Change Adaptation in Agriculture.  </p>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-4 '>
                        <div className='sec6_ul text-end'>
                            <ul>
                                <li>
                                    <Link to="/about" className='sec6_li'>ABOUT</Link>
                                </li>
                                <li>
                                    <Link to="/ourwork" className='sec6_li'>OUR WORK</Link>
                                </li>
                                <li>
                                    <Link to="/gallery" className='sec6_li'>GALLERY</Link>
                                </li>
                                <li>
                                    <Link to="/contact" className='sec6_li'>CONTACT US</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='sec6_last'>
                    <div className='sec6_last1'>
                        © 2024 FarmBridge Social Support Foundation.All Rights Reserved.
                        <Link className="text-reset fw-bold" to="https://farmbridge.org/">FarmBridge.com</Link>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default Footer;