import React from 'react';
import '../Ourwork.css'
import { Link } from 'react-router-dom';
import Corporate  from '../images/Corporate .png'

const Ourwork = () => {
    return (
        <>
            <div className="container-fluid">
                <div className='row ourwork'>
                    <div className=''>
                        <h1 className='cont'>Farmbridge Social Support Foundation</h1>
                    </div>
                    <div className='col-sm-12 col-md-12 col-xl-6'>
                        <div className=''>
                            <p className='ourwork_cont'> We play a pivotal role in supporting corporate entities with their Corporate Social Responsibility (CSR) initiatives, particularly focusing on the betterment of beneficiary communities and villages.

                                Our expertise lies in facilitating CSR programs that span livelihood and skill development, along with initiatives related to Community Health & Environment.
                                By partnering with us, corporations actively contribute to the empowerment of diverse groups within rural areas, including community members, the rural poor, women, rural youth, and farmers.
                            </p>
                            <p className='ourwork_cont'>
                                Through our collaborative efforts, we aim to bring about positive and sustainable change, fostering development in key areas that are essential for the overall well-being of these communities. Join hands with Farmbridge in making a meaningful impact and advancing CSR initiatives that leave a lasting and positive legacy.
                            </p>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-12 col-xl-6'>
                        <div className='ourwork_sec1'>
                            <p className=''>We have facilitated corporate/organizations like</p>
                            <ul>
                                <li>
                                    <div className='ourwork_li1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" fill='#f7f052' /></svg>
                                        <p>
                                            NTPC Ltd
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className='ourwork_li1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" fill='#f7f052' /></svg>
                                        <p>
                                            NCL-Coal India Ltd
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className='ourwork_li1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" fill='#f7f052' /></svg>
                                        <p>
                                            Jubliant Life Sciences
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className='ourwork_li1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" fill='#f7f052' /></svg>
                                        <p>
                                            Unique Pharmaceuticals
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className='ourwork_li1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" fill='#f7f052' /></svg>
                                        <p>
                                            Bharati Modi Foundation
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className='ourwork_li1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" fill='#f7f052' /></svg>
                                        <p>
                                            CLP India
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className='ourwork_li1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" fill='#f7f052' /></svg>
                                        <p>
                                            Aarti Industries
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className='ourwork_li1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" fill='#f7f052' /></svg>
                                        <p>
                                            Alembic Foundation
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className='ourwork_li1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" fill='#f7f052' /></svg>
                                        <p>
                                            Jubliant Bharti Foundation
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className='ourwork_li1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" fill='#f7f052' /></svg>
                                        <p>
                                            Galaxy surfactants
                                        </p>
                                    </div>
                                </li>
                            </ul>
                            <p>in different locations of states of the country like Gujarat, Rajsthan, Uttar Pradesh, Bihar, Telangana & Madhya Pradesh.</p>
                        </div>
                    </div>
                </div>
                <div className='corpo'>
                    <img src={Corporate} alt="" className='img-fluid' />
                </div>

                <div className='ourwork_btn'>
                    <div className='ourwork_btn1'>
                        <Link to="/" className='ourwork_btn2'>BACK</Link>
                    </div>
                </div>
            </div>

            
        </>
    )
}

export default Ourwork;